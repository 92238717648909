exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-concept-tsx": () => import("./../../../src/pages/concept.tsx" /* webpackChunkName: "component---src-pages-concept-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-recruit-tsx": () => import("./../../../src/pages/recruit.tsx" /* webpackChunkName: "component---src-pages-recruit-tsx" */),
  "component---src-pages-service-for-business-tsx": () => import("./../../../src/pages/service/for-business.tsx" /* webpackChunkName: "component---src-pages-service-for-business-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

